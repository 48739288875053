import React, { useEffect, useState } from "react";
import ProfileForm from "../../components/modules/profile/profileForm";

import { getProfile, getManagersIndex, getGroupIndex } from "../../api/profile";

export default function ProfileView(props) {
  const [data, setData] = useState(false);

  const [lineManagers, setLineManagers] = useState([]);
  const [groups, setGroups] = useState([]);

  const getData = async () => {
    getUsersData();
    getLineManagersData();
    getGroupData();
  };

  const getUsersData = async () => {
    const res = await getProfile(props.match.params.id);

    setData(res.data);
  };

  const getLineManagersData = async () => {
    const res = await getManagersIndex();

    setLineManagers(res.data);
  };
  const getGroupData = async () => {
    const res = await getGroupIndex();

    setGroups(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <>
        <ProfileForm
          data={data}
          type={"view"}
          lineManagersData={lineManagers}
          groups={groups}
        />
      </>
    );
  } else return <p>Loading</p>;
}
