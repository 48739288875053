import AppButton from "../../common/AppButton";
import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

export default function ApplicationsForm({
    data,
    applications,
    appsToAdd,
    handleChange,
    handleSave
}) {
    return (
        <>
            <div className="container">
                <div className="block">
                    <div className="container-fluid">
                        <div className="form-row">
                            <div className="form-group col-lg-8">
                                <h3>Applications</h3>    
                            </div>
                            <div className="form-group col-lg-1">
                                <Link
                                    to={{
                                       pathname: "/profiles"
                                    }}
                                >
                                    <AppButton
                                        text="Back"
                                        btnIcon="cancel"
                                        style={{
                                            color: "white",
                                            background: "red"
                                        }}
                                    /> 
                                </Link>
                            </div>
                            <div className="form-group col-lg-3">
                                <AppButton
                                    text="Register Applications"
                                    btnIcon="save"
                                    style={{
                                        color: "white",
                                        background: "green"
                                    }}
                                    onClick={() => {
                                        handleSave();
                                    }}
                                />
                            </div>
                        </div>
                        <div className="block">
                            <div className="form-row">
                                <h4 className="section-header">To Add:</h4>
                            </div>
                            <hr/>
                            <div className="form-row">
                                {applications && appsToAdd &&
                                    applications.map((e, key) => (
                                        appsToAdd[e.id] ? (
                                            <div className="col-lg-3 app-box" key={key}>
                                                <p><b>{e.name}</b></p>
                                                <AppButton
                                                    text="remove"
                                                    btnIcon="delete"
                                                    onClick={() => handleChange(e.id, false)}
                                                    style={{
                                                        background: "red",
                                                        color: "white"
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <></>
                                        )

                                    ))}
                            </div>
                        </div>
                        <div className="block">
                            <div className="form-row">
                                <h4 className="section-header">To Remove:</h4>
                            </div>
                            <hr/>
                            <div className="form-row">
                                {applications && appsToAdd &&
                                    applications.map((e, key) => (
                                    !appsToAdd[e.id] ? (
                                        <div className="col-lg-3 app-box" key={key}>
                                            <p><b>{e.name}</b></p>
                                            <AppButton
                                                text="add"
                                                btnIcon="add"
                                                onClick={() => handleChange(e.id, true)}
                                                style={{
                                                    background: "green",
                                                    color: "white"
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                    ))}
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </>
    )
}