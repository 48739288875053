import React, { useState, useEffect, useContext } from "react";
import FilterTable from "../../components/common/FilterTable";
import PageHeader from "../../components/common/PageHeader";
import { CanDelete, CanUpdate } from "../../services/Permissions";
import { Link } from "react-router-dom";
import { deleteClient, getClients } from "../../api/clients";
import Loading from "../../components/common/Loading";
import AppButton from "../../components/common/AppButton";
import AuthContext from "../../services/AuthContext";
import { getTicketIndex } from "../../api/ticket";
export default function Tickets() {
    const context = useContext(AuthContext);
    const [data, setData] = useState(false);
    const [backup, setBackup] = useState(false);
    const [open, setOpen] = useState({ state: false });

    const [listType, setListType] = useState(false);


    const getTickets = async () => {
        const data = await getTicketIndex(context.user.client);
        console.log(data.data);
        setData(data.data);
    };

    useEffect(() => {
        getTickets();
    }, []);

    const columns = [
        { name: "id", label: "Ticket ID" },
        { name: "created_at", label: "Date Created", options: {
            customBodyRender: (created_at) => {
                var brokenDate = created_at.split("T");
                var createdAt = new Date( brokenDate[0] + " " + brokenDate[1] + " UTC" );
                return (<>{ createdAt.toLocaleString([], { dateStyle: "medium", timeStyle: "short" }) }</>);
            }
        } },
        { name: "updated_at", label: "Last Updated", options: {
            customBodyRender: (updated_at) => {
                var brokenDate = updated_at.split("T");
                var updated_at = new Date( brokenDate[0] + " " + brokenDate[1] + " UTC" );
                return (<>{ updated_at.toLocaleString([], { dateStyle: "medium", timeStyle: "short" }) }</>);
            }
        } },
        { name: "ticket_name", label: "Ticket Name" },
        { 
            name: "current_status", 
            label: "Ticket Status",
            options: {
                filterList: listType ? [listType] : [],
                customBodyRender: (status) => {
                    return (
                        <div>
                            {status ? "Closed" : "Open"}
                        </div>
                    )
                },
                filterOptions: {
                    names: ["Open", "Closed"],
                    logic: (status, filters, row) => {
                        var s = status ? "Closed" : "Open";
                        return filters.length !== 0 && !filters.includes(s);
                    }
                }
            }
        },
        {
            name: "",
            label: "Action",
            options: {
                customBodyRenderLite: (dataIndex) => {

                    return (
                        <div>
                            <Link to={`/tickets/edit/${data[dataIndex].id}`}>
                                <AppButton text="Update ticket" btnIcon="save" style={{ color: "white", background: "blue" }} />
                            </Link>
                        </div>
                    );
                },
                filter: false,
            },
        },
    ];


    const options = {
        filterType: "dropdown",
        responsive: "stacked",
        print: true,
        rowsPerPage: 10,
        page: 0,
        selectableRows: "none"
    };

    if (data) {
        return (
            <div className="container">
                <div className="block" style={{ marginBottom: 10, textAlign: "right" }}>
                    <AppButton
                        text="Open Tickets"
                        btnIcon=""
                        style={{ color: "white", background: "purple", marginLeft: 10 }}
                        onClick={() => {setListType("Open")}}
                    />
                    <AppButton
                        text="Closed Tickets"
                        btnIcon=""
                        style={{ color: "white", background: "purple", marginLeft: 10 }}
                        onClick={() => {setListType("Closed")}}
                    />
                    <AppButton
                        text="All Tickets"
                        style={{ color: "white", background: "purple", marginLeft: 10 }}
                        onClick={() => {setListType(false)}}
                    />
                    <Link to="/tickets/create">
                        <AppButton
                            text="create new"
                            btnIcon="add"
                            style={{ color: "white", background: "blue", marginLeft: 10 }}
                        />
                    </Link>
                </div>
                <FilterTable columns={columns} data={data} options={options} />
            </div>
        );
    } else {
        return <Loading color={"blue"} />;
    }
}
