import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { InputLabel, MenuItem, Select, Menu } from "@material-ui/core";
import React, { useState } from "react";
import { createFeedback } from "../../api/feedback";
import ApiLoader from "../../components/common/ApiLoader";
import AppButton from "../../components/common/AppButton";

export default function Feedback() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({ status: false, data: false });

  const relatedSystems = [
    { id: 1, name: "Portal" },
    { id: 2, name: "TimeSheet" },
    { id: 3, name: "Marketing" },
    { id: 4, name: "Sales" },
    { id: 5, name: "Support" },
    { id: 6, name: "Contract" },
    { id: 7, name: "Purchasing" },
    { id: 8, name: "ABC / Job Costing" },
    { id: 9, name: "Fleet" },
    { id: 10, name: "Profile" },
    { id: 11, name: "Customers" },
  ];

  const typeOptions = [
    { id: 1, name: "Bug" },
    { id: 2, name: "Improvement / Addition" },
    { id: 3, name: "Investigation" },
  ];

  /*   const handleSave = async () => {
    setLoading({ status: true, data: "Saving Please Wait..." });
    await createFeedback(data);
    setLoading({ status: true, data: "Feedback Submitted, Thankyou !" });
    setData({});
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }; */

  return (
    <>
      <div className="container">
        <div className="block" style={{ marginBottom: 10, textAlign: "right" }}>
          <a
            href={encodeURI(
              "mailto:" +
                "uais@unity.world" +
                "" +
                "?subject=" +
                data.related_system +
                " " +
                data.type +
                "&body=" +
                data.description +
                " " +
                "Date: " +
                data.date
            )}
          >
            <AppButton
              text="submit feedback"
              btnIcon="save"
              style={{ color: "white", background: "blue" }}
            />
          </a>
        </div>

      <div className="block">
        <div className="form-row">
          <div className="form-group col-lg-6">
            <InputLabel>Related System</InputLabel>
            <Select
              fullWidth
              name="related_system"
              value={data.related_system ? data.related_system : ""}
              onChange={(e) =>
                setData({ ...data, related_system: e.target.value })
              }
            >
              {relatedSystems.map((e, key) => (
                <MenuItem key={key} value={e.name}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="form-group col-lg-6">
            <InputLabel>Type</InputLabel>
            <Select
              fullWidth
              name="related_system"
              value={data.type ? data.type : ""}
              onChange={(e) => setData({ ...data, type: e.target.value })}
            >
              {typeOptions.map((e, key) => (
                <MenuItem key={key} value={e.name}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="form-group col-lg-6">
            <InputLabel>Date / Time Occured: </InputLabel>
            <TextField
              name="date"
              type="datetime-local"
              value={data.date ? data.date : ""}
              onChange={(e) => setData({ ...data, date: e.target.value })}
              fullWidth={true}
            ></TextField>
          </div>
        </div>

        <TextField
          style={{ marginBottom: 20 }}
          rows={10}
          fullWidth
          multiline
          name="description"
          label="Description:"
          value={data.description}
          onChange={(e) => setData({ ...data, description: e.target.value })}
        />
        {/*        <FormControlLabel
          control={
            <Checkbox
              checked={data.contact_me}
              onChange={(e) =>
                setData({ ...data, contact_me: e.target.checked })
              }
              color="primary"
            />
          }
          label="Should someone from unity contact you ?"
        /> */}
        <ApiLoader color={"blue"} status={loading.status} data={loading.data} />
      </div>
    </div>
    </>
  );
}
