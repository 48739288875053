import { FormControlLabel, Select, Switch, TextField } from "@material-ui/core";
import React from "react";

export default function PortalForm(props) {
  const handleChange = (e, key, key2) => {
    const newForm = { ...props.form };
    newForm["client_site"][key].site_contact[key2][e.target.name] =
      e.target.value;
    props.setForm(newForm);
  };

  const handleToggle = (e, key, key2) => {
    const newForm = { ...props.form };
    newForm["client_site"][key].site_contact[key2][e.target.name] =
      e.target.checked;
    props.setForm(newForm);
  };

  return (
    <div className="block">
      {props.form.client_site.map((site, key) => {
        return site.site_contact.map((contact, key2) => {
          return (
            <div className="form-row">
              <div className="form-group col-lg-3">
                <TextField
                  label="Email Address"
                  fullWidth={true}
                  value={contact.site_contact_email}
                  onChange={(e) => handleChange(e, key, key2)}
                  name="portal_email"
                />
              </div>
              <div className="form-group col-lg-3">
                <TextField
                  label="Password"
                  fullWidth={true}
                  disabled={contact.portal_access ? false : true}
                  onChange={(e) => handleChange(e, key, key2)}
                  name="portal_password"
                  helperText={"Leave blank for random generated"}
                />
              </div>
              <div className="form-group col-lg-3">
                <FormControlLabel
                  control={
                    <Switch
                      name="portal_access"
                      checked={contact.portal_access}
                      onChange={(e) => handleToggle(e, key, key2)}
                      color="primary"
                    />
                  }
                  label="Portal Access"
                />
              </div>
            </div>
          );
        });
      })}
    </div>
  );
}
