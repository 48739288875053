import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

export default function PositionPicker({
    data,
    positions,
    selectedPositions,
    handlePositionChange
}) {
    return (
        <>
            <div className="container">
                <div className="block">
                    <div className="container-fluid">
                        <div className="form-row">
                            <div className="form-group col-lg-10">
                                <h4>Positions</h4>    
                            </div>
                        </div>
                        <div className="form-row">
                        {positions && 
                            positions.map((e, key) => (
                                <div className="col-lg-4" key={key}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={selectedPositions[e.position_id]} onChange={(event) => handlePositionChange(e.position_id, event.target.checked)} name={e.name}
                                            />
                                        }
                                        label={e.name}
                                    />
                                </div>
                            ))
                        }
                        </div>
                    </div>    
                </div>    
            </div> 
        </>
    )
}