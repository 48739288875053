import config from "../config";

const chatShow = async (id) => {
    const data = await fetch(`${config.appURL}/api/livechat/chatShow?id=${id}`, {
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });

    const res = await data.json();

    return res;
};

const chatIndexByTicketArray = async (arr) => {
    const data = await fetch(`${config.appURL}/api/livechat/indexByTicketArray`, {
        credentials: "include",
        method: 'POST',
        body: JSON.stringify(arr),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });

    const res = await data.json();

    return res;
};

const chatStore = async (obj) => {
    const data = await fetch(`${config.appURL}/api/livechat/chatStore`, {
        credentials: "include",
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });

    const res = await data.json();

    return res;
};

export { chatShow, chatStore, chatIndexByTicketArray }