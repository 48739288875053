import React, { useState, useEffect, useContext } from "react";
import FilterTable from "../../components/common/FilterTable";
import PageHeader from "../../components/common/PageHeader";
import { CanDelete, CanUpdate } from "../../services/Permissions";
import { Link } from "react-router-dom";
import { deleteClient, getClients } from "../../api/clients";
import Loading from "../../components/common/Loading";
import AppButton from "../../components/common/AppButton";
import AuthContext from "../../services/AuthContext";
export default function Clients() {
  const context = useContext(AuthContext);
  const [data, setData] = useState(false);
  const [backup, setBackup] = useState(false);
  const [open, setOpen] = useState({ state: false });

  if (CanDelete("clients")) {
    select = "multiple";
  } else {
    select = "none";
  }

  const clients = async () => {
    const data = await getClients();
    console.log(data.data);
    setData(data.data);
  };

  useEffect(() => {
    clients();
  }, []);

  const columns = [
    { name: "id", label: "Reference" },
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    { name: "phone", label: "Phone" },
    { name: "mobile", label: "Mobile" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = CanUpdate("clients");

          return (
            <div>
              {edit && (
                <Link
                  to={{
                    pathname: `/clients/update/${data[dataIndex].id}`,
                    state: { client: data[dataIndex].id },
                  }}
                >
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: "blue",
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}
            </div>
          );
        },
        filter: false,
      },
    },
  ];

  if (CanDelete("clients")) {
    var select = "multiple";
  } else {
    var select = "none";
  }

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    onRowsDelete: (rowsDeleted) => {
      var records = [];

      rowsDeleted.data.map(async (item, key) => {
        records.push({
          id: data[item.dataIndex].id,
          name: data[item.dataIndex].name,
          data: item,
        });
      });
      setOpen({
        state: true,
        title: "Are you sure you want to delete?",
        description:
          "Are you sure you want to delete the following records, these records will no longer be available across your software suite",
        data: records,
      });
    },
  };

  if (data) {
    return (
      <div className="container">
        <div className="block" style={{ marginBottom: 10, textAlign: "right" }}>
          <Link to="/clients/create">
            <AppButton
              text="create new"
              btnIcon="add"
              style={{ color: "white", background: "blue" }}
            />
          </Link>
        </div>
        <FilterTable columns={columns} data={data} options={options} />
      </div>
    );
  } else {
    return <Loading color={"blue"} />;
  }
}
