/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/tenant-service/roles
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://tenant-service.rrdevelopment.co.uk/api/roles
 */
import config from "../../../config";

/**
 * Tenants Service https://appURL/api/tenant-service/roles/
 * @method \ GET
 * @return \ json
 */

const getRoles = async () => {
  const data = await fetch(`${config.appURL}/api/tenant-service/roles/get`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * Tenants Service https://appURL/api/tenant-service/roles/
 * @method \ POST
 * @param  \ Roles Array
 * @return \ json
 */

const saveRoles = async (roles) => {
  const data = await fetch(`${config.appURL}/api/tenant-service/roles/create`, {
    method: "POST",
    body: JSON.stringify(roles),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteRoles = async (id) => {
  const data = await fetch(
    `${config.appURL}/api/tenant-service/roles/delete?id=${id}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

export { getRoles, saveRoles, deleteRoles };
