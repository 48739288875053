import React from "react";
import AppButton from "./AppButton";
import data from "./DocumentIcons";
export default function DocumentCard(props) {
  return (
    <div className="col-lg-3" key={props.key} style={{ marginBottom: 30 }}>
      <div
        style={{
          width: "95%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-4"
              style={{ paddingRight: 0, textAlign: "center" }}
            >
              <img
                style={{ width: "80%", marginTop: 5 }}
                src={data[props.item.type]}
              />
            </div>
            <div className="col-lg-8">
              <div style={{ height: 48 }}>{props.item.name}</div>
              <div>{props.item.created_at}</div>
              <div style={{ fontSize: 14 }}>
                <b>{props.item.size}</b> in size
              </div>
            </div>
          </div>
        </div>

        <AppButton
          text="download"
          btnIcon="download"
          style={{
            color: "white",
            background: "#5192ec",
            width: "100%",
            marginTop: 10,
          }}
          onClick={() => props.getDoc(props.item.id)}
        />
      </div>
    </div>
  );
}
