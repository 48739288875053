import { FormControlLabel, Switch } from "@material-ui/core";
import React from "react";

export default function ApplicationPicker({
    data,
    applications,
    selectedApplications,
    handleApplicationChange
}) {
    return (
        <>
            <div className="container">
                <div className="block">
                    <div className="container-fluid">
                        <div className="form-row">
                            <div className="form-group col-lg-10">
                                <h4>Applications</h4>    
                            </div>
                        </div>
                        <div className="form-row"> 
                        {applications && 
                            applications.map((e, key) => (
                                <div className="col-lg-4" key={key}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={selectedApplications[e.id]} onChange={(event) => handleApplicationChange(e.id, event.target.checked)} name={e.name}
                                            />
                                        }
                                        label={e.name}
                                    />
                                </div>
                            ))
                        }
                        </div>
                    </div>    
                </div>    
            </div> 
        </>
    )
}