import React, { useContext, useEffect, useState } from "react";
import { getDocumentsByCustomer, getLink } from "../../api/documents";
import DocumentCard from "../../components/common/DocumentCard";
import AuthContext from "../../services/AuthContext";

export default function DocumentView() {
  const [docs, setDocs] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const context = useContext(AuthContext);

  const getDocs = async () => {
    const res = await getDocumentsByCustomer(context.user.client);
    setDocs(res.data);
  };

  const getDoc = async (id) => {
    setDownloading(id);
    const res = await getLink(id);
    setDownloading(false);
    var win = window.open(res.data, "_blank");
    win.focus();
  };

  console.log(docs);

  useEffect(() => {
    getDocs();
  }, []);

  return (
    <div className="container">
      {docs && docs.length > 0 ? (
        <div className="row" style={{ marginTop: 20 }}>
          {docs.map((item, key) => {
            return <DocumentCard item={item} key={key} getDoc={getDoc} />;
          })}
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <p>No Documents Yet</p>
          </div>
        </div>
      )}
    </div>
  );
}
