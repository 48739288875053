/**
 * @service         \ https://finance-service.rrdevelopment.co.uk
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://fianance-service.rrdevelopment.co.uk/api/position
 */
 import config from "../../../config";

 /**
 * Finance Service https://appURL/api/finance-service/position/
 * @method \ GET
 * @return \ json
 */
const getPositions = async() => {
    const data = await fetch(`${config.appURL}/api/finance-service/position/getAll`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
    
      return res;
}

 /**
 * Finance Service https://appURL/api/finance-service/position/indexForUser
 * @method \ GET
 * @return \ json
 */
const getPositionsForUser = async(user_id) => {
    const data = await fetch(`${config.appURL}/api/finance-service/position/indexForUser/get?user_id=${user_id}`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
    
      return res;
}

 /**
 * Finance Service https://appURL/api/finance-service/position/restoreIndex
 * @method \ GET
 * @return \ json
 */
const getPositionsRestoreIndex = async() => {
    const data = await fetch(`${config.appURL}/api/finance-service/position/restoreIndex/get`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
    
      return res;
}

 /**
 * Finance Service https://appURL/api/finance-service/position/{id}
 * @method \ GET
 * @return \ json
 */
const getPosition = async(position_id) => {
    const data = await fetch(`${config.appURL}/api/finance-service/position/get?position_id=${position_id}`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
    
      return res;
}

 /**
 * Finance Service https://appURL/api/finance-service/position
 * @method \ POST
 * @return \ json
 */
const savePosition = async(position) => {
    const data = await fetch(`${config.appURL}/api/finance-service/position/create`, {
        method: "POST",
        body: JSON.stringify(position),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
    
      return res;
}

 /**
 * Finance Service https://appURL/api/finance-service/position/{id}
 * @method \ PUT
 * @return \ json
 */

const updatePosition = async(position_id, position) => {
    const data = await fetch(`${config.appURL}/api/finance-service/position/update?position_id=${position_id}`, {
        method: "PUT",
        body: JSON.stringify(position),
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });

    const res = await data.json();

    return res;
}

 /**
 * Finance Service https://appURL/api/finance-service/position/{id}
 * @method \ DELETE
 * @return \ json
 */
const deletePosition = async(position_id) => {
    const data = await fetch(`${config.appURL}/api/finance-service/position/delete?position_id=${position_id}`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
    
      return res; 
}

/**
 * Finance Service https://appURL/api/finance-service/position/{id}/restore
 * @method \ GET
 * @return \ json
 */
const restorePosition = async(position_id) => {
    const data = await fetch(`${config.appURL}/api/finance-service/position/restore?position_id=${position_id}`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const res = await data.json();

      return res; 
}

 /**
 * Finance Service https://appURL/api/finance-service/position/set
 * @method \ POST
 * @return \ json
 */
  const setPosition = async(position) => {
    const data = await fetch(`${config.appURL}/api/finance-service/position/set`, {
        method: "POST",
        body: JSON.stringify(position),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
    
      return res;
}

 /**
 * Finance Service https://appURL/api/finance-service/position/unset
 * @method \ DELETE
 * @return \ json
 */
  const unsetPosition = async(position_id, user_id) => {
    const data = await fetch(`${config.appURL}/api/finance-service/position/unset?position_id=${position_id}&user_id=${user_id}`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    
      const res = await data.json();
    
      return res; 
}

export { getPositions, getPositionsForUser, getPositionsRestoreIndex, getPosition, savePosition, updatePosition, deletePosition, restorePosition, setPosition, unsetPosition };