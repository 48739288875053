import AppButton from "../../common/AppButton";
import React, { useContext } from "react";
import { FormControlLabel, InputLabel, MenuItem, Select, TextField, Checkbox } from "@material-ui/core";
import { Link, Switch } from "react-router-dom";

import PositionPicker from "./new-user/position-picker";
import ApplicationPicker from "./new-user/application-picker";
import AuthContext from "../../../services/AuthContext";

export default function NewUserForm({
    data,
    handleSave,
    handleChange,
    handleGroupChange,
    handleRoleChange,
    handleApplicationChange,
    handlePositionChange,
    groups,
    applications,
    selectedApplications,
    positions,
    selectedPositions,
    roles
}) {
    const context = useContext(AuthContext);

    return (groups && roles && positions && applications) ? (
        <>
            <div className="container">
                <div className="block">
                    <div className="container-fluid">
                        <div className="form-row">
                            <div className="form-group col-lg-10">
                                <h3>New User</h3>    
                            </div>
                            <div className="form-group col-lg-2">
                                <AppButton
                                    text="Register User"
                                    btnIcon="add"
                                    style={{
                                        color: "white",
                                        background: "green"
                                    }}
                                    onClick={() => {
                                        handleSave();
                                    }}
                                />    
                            </div>    
                        </div>
                        <div className="form-row">
                            <div className="form-group col-lg-6">
                                <InputLabel>Email Address:</InputLabel>
                                <TextField
                                    name="user_email"
                                    type="text"
                                    value={data.user_email}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    fullWidth
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <InputLabel>Fullname:</InputLabel>
                                <TextField
                                    name="user_fullName"
                                    type="text"
                                    value={data.user_fullName}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-lg-6">
                                <InputLabel>Department:</InputLabel>
                                <Select
                                    name="user_group_uuid"
                                    required
                                    fullWidth
                                    value={data.group ? data.group : groups[0]}
                                    defaultValue=""
                                    onChange={(e) => handleGroupChange(e.target.value)}
                                >
                                 {groups &&
                                    groups.map((e, key) => (
                                        <MenuItem key={key} value={e}>
                                            {e.group_name}    
                                        </MenuItem>
                                    ))}   
                                </Select>
                            </div>
                            <div className="form-group col-lg-6">
                                <InputLabel>Portal Access Level:</InputLabel>
                                <Select
                                    name="portal_role_id"
                                    required
                                    fullWidth
                                    value={data.role ? data.role : roles[0]}
                                    defaultValue=""
                                    onChange={(e) => handleRoleChange(e.target.value)}
                                >
                                    {roles &&
                                        roles.map((e, key) => (
                                            <MenuItem key={key} value={e}>
                                                {e.name}    
                                            </MenuItem>
                                        ))}
                                </Select>
                            </div>
                        </div>
                        { context.user.super_admin && (
                            <div className="form-row">
                                <div className="form-group col-lg-6">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.line_manager}
                                                onChange={(event) => handleChange(event.target.name, event.target.checked)}
                                                name="line_manager"
                                            />
                                        }
                                        label="Line Manager"
                                    />
                                </div>
                            </div>
                        )}

                        <PositionPicker
                            data={data}
                            positions={positions}
                            selectedPositions={selectedPositions}
                            handlePositionChange={handlePositionChange}
                        />

                        <ApplicationPicker
                            data={data}
                            applications={applications}
                            selectedApplications={selectedApplications}
                            handleApplicationChange={handleApplicationChange}
                        />

                        <hr/>
                        <div className="form-row">
                            <div className="col-lg-10">
                                <Link
                                    to={{
                                        pathname: `/profiles`,
                                        state: {}
                                    }}
                                >
                                    <AppButton
                                            text="Cancel"
                                            btnIcon="cancel"
                                            style={{
                                                color: "white",
                                                background: "red"
                                            }}
                                    />
                                </Link>
                            </div>
                            <div className="col-lg-2">
                                    <AppButton
                                        text="Register User"
                                        btnIcon="add"
                                        style={{
                                            color: "white",
                                            background: "green"
                                        }}
                                        onClick={() => {
                                            handleSave();
                                        }}
                                    />    
                            </div>
                        </div>
                    </div>
                </div>     
            </div>
        </>
    ) :
    (
        <p> Loading </p>
    )
}