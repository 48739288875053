import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../services/AuthContext";

import config from "../../config";
import PositionForm from "../../components/modules/positions/positionForm";
import ApiLoader from "../../components/common/ApiLoader";
import {
    getPosition,
    updatePosition
} from "../../api/services/finance/positions";
import {
    getGroupIndex
} from "../../api/profile";
import { useParams } from "react-router-dom";

export default function PositionEdit(props) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState({ status: false, data: "" });
    const [groups, setGroups] = useState([]);
    const params = useParams();

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    }

    const handleGroupChange = (group) => {
        setData({ ...data, "group_uuid" : group.group_uuid, "group": group });
    }

    const handleSave = async () => {
        setLoading({ status: true, data: "Updating Position, Please Wait." });
        const res = await updatePosition( params.id, data );

        setTimeout(() => {
            window.history.go(-1);
            setLoading({ status: false });
        }, 4000);
    }

    const getGroupsData = async () => {
        const res = await getGroupIndex();
    
        setGroups(res.data);
    };

    const getPositionData = async () => {
        const res = await getPosition( params.id );
        setData(res.data);
    }

    useEffect(() => {
        getGroupsData();
        getPositionData();
    }, [])

    return (
        <>
            <ApiLoader status={loading.status} data={loading.data} />

            <PositionForm
                data={data}
                handleSave={handleSave}
                handleChange={handleChange}
                groups={groups}
                handleGroupChange={handleGroupChange}
                editFlag={true}
            />
        </>
    )
}