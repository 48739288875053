import React from "react";
import MUIDataTable from "mui-datatables";
/*
  It uses npm mui-datatables. It's easy to use, you just describe columns and data collection.
  Checkout full documentation here :
  https://github.com/gregnb/mui-datatables/blob/master/README.md
*/
export default function AdvFilter({ columns, data, title, options }) {
  return (
    <MUIDataTable
      data={data}
      columns={columns}
      options={options}
      title={title}
    />
  );
}
