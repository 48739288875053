import React, { useEffect, useState } from "react";
import { getLongLat } from "../../api/postcode";
import ApiLoader from "../../components/common/ApiLoader";
import ClientReports from "../../components/common/ClientReports";
import PageHeader from "../../components/common/PageHeader";
import ClientForm from "../../components/modules/clients/ClientForm";
import { useHistory, useLocation } from "react-router-dom";
import { createClientMethod } from "../../api/group/clients/createClient";
import { saveClient } from "../../api/clients";
import AppButton from "../../components/common/AppButton";

export default function ClientCreate(props) {
  const [form, setForm] = useState({ client_site: [] });
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [errors, setErrors] = useState(false);
  const [docs, setDocs] = useState([]);
  let history = useHistory();
  let location = useLocation();
  const checkBackup = async () => {
    if (props.location.state) {
      setForm(props.location.state.backup);
      localStorage.removeItem("cForm");
    }
  };

  useEffect(() => {
    checkBackup();
  }, []);
  const validate = {
    client_name: "required",
    client_tel: "required",
  };

  const validation = async (obj) => {
    delete obj.client_site;
    console.log(obj);
    const x = {};

    Object.keys(validate).map((key, val) => {
      obj[key] = obj[key];
    });

    console.log(obj);

    Object.keys(obj).map((key) => {
      if (validate[key]) {
        if (!obj[key]) {
          x[key] = true;
        }
      }
    });

    if (Object.keys(x).length > 0) {
      return x;
    } else {
      return true;
    }
  };

  const setContactValue = (e, index, index2) => {
    const newData = { ...form };
    newData.client_site[index].site_contact[index2][e.target.name] =
      e.target.value;
    setForm(newData);
  };

  const getGeoLocation = async (e, index) => {
    const postcode = e.target.value;
    const data = await getLongLat(postcode.replace(" ", ""));
    const newData = { ...form };
    if (data.status === 200) {
      newData.client_site[index].site_postcode = postcode;
      newData.client_site[index].site_long = data.result.longitude;
      newData.client_site[index].site_lat = data.result.latitude;
      setForm(newData);
    }
  };

  const setAddressValue = (e, id) => {
    const newData = { ...form };
    newData.client_site[id][e.target.name] = e.target.value;
    setForm(newData);
  };

  const addAddress = () => {
    const newData = { ...form };
    newData.client_site.push({ site_contact: [] });
    setForm(newData);
  };

  const onChange = (e) => {
    form[e.target.name] = e.target.value;
    setUpdate(!update);
  };

  const onSubmit = async () => {
    setLoading({
      status: !loading.status,
      data: "Saving your record, Please Wait....",
    });
    await saveClient(form);
    setLoading({ status: true, data: "Save Success!, Please Wait...." });
    setTimeout(() => {
      setForm(false);
      history.push("/customers");
    }, 2000);
  };

  const addContact = (site) => {
    const data = { ...form };
    data.client_site[site].site_contact.push({});
    setForm(data);
  };

  const removeContact = (site, contact) => {
    const data = { ...form };
    data.client_site[site].site_contact.splice(contact, 1);
    setForm(data);
  };

  const removeSite = (site) => {
    const data = { ...form };
    data.client_site.splice(site, 1);
    setForm(data);
  };
  const addSiteDocs = (e, site) => {
    const data = { ...form };
    data.client_site[site]["site_docs"] = e;
    setForm(data);
  };

  const upload = (e) => {
    const newData = { ...form };
    newData["file_path"] = [e]; // in array for single file // remove for multi
    setForm(newData);
  };
  console.log(form);

  return (
    <div className="container">
      <ApiLoader status={loading.status} data={loading.data} />
      <div className="block" style={{ textAlign: "right" }}>
        <AppButton
          text="save"
          onClick={() => onSubmit()}
          btnIcon="save"
          style={{ color: "white", background: "#35d835" }}
        />
      </div>
      <ClientForm
        type="create"
        data={form}
        errors={errors}
        onChange={onChange}
        addAddress={addAddress}
        removeSite={removeSite}
        setAddressValue={setAddressValue}
        upload={upload}
        addContact={addContact}
        removeContact={removeContact}
        setContactValue={setContactValue}
        getGeoLocation={getGeoLocation}
        addSiteDocs={addSiteDocs}
      />
    </div>
  );
}
