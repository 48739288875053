import React, { useEffect } from "react";
import { useState, useContext } from "react";
import FilterTable from "../../components/common/FilterTable";
import { Link } from "react-router-dom";
import AppButton from "../../components/common/AppButton";
import AuthContext from "../../services/AuthContext";
import {
  getProfile,
  updateProfile,
  saveImage,
  savePassword,
  getManagersIndex,
  getAgents,
  lockAccount,
  unlockAccount
} from "../../api/profile";
import {
  deleteUser,
  restoreUser
} from "../../api/services/tenant/users";
import { Card, CardContent } from "@material-ui/core";

export default function ProfileIndex() {
  const [data, setData] = useState([{}]);
  const [managerData, setManagerData] = useState(false);

  const context = useContext(AuthContext);
  console.log( context );

  const getIndexData = async () => {
    const res = await getAgents();

    setData(res.data);
  };
  const getManagerData = async () => {
    const res = await getManagersIndex();

    setManagerData(res.data);
  };
  

  const getData = async () => {
    getIndexData();
    getManagerData();
  };

  const lockUserAccount = async (id, uuid) => {
    await deleteUser(id);

    await lockAccount(uuid);

    getIndexData();
  }

  const unlockUserAccount = async (id, uuid) => {
    await restoreUser(id);

    await unlockAccount(uuid);

    getIndexData();
  }

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { name: "name", label: "Name" },
    { name: "group_name", label: "Group Name" },
    {
      name: "manager_id",
      label: "Manager",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const managerId = data[dataIndex].manager_id;
          if (managerId && managerData) {
            const data = managerData.find((e) => e.id === managerId);

            if (typeof data !== "undefined") return data.name;

            return "No Manager Assigned";
          }

          return "No Manager Assigned";
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const lockButton = data[dataIndex].active ? (
            <>
              <AppButton
                text="lock"
                btnIcon="lock"
                style={{
                  background: "red",
                  color: "white",
                  marginLeft: 10
                }}
                onClick={() => {
                  lockUserAccount( data[dataIndex].id, data[dataIndex].uuid );
                }}
              />
            </>
          ) : (
            <>
              <AppButton
                text="unlock"
                btnIcon="lockopen"
                style={{
                  background: "yellow",
                  color: "black",
                  marginLeft: 10
                }}
                onClick={() => {
                  unlockUserAccount( data[dataIndex].id, data[dataIndex].uuid );
                }}
              />
            </>
          );

          return (
            <>
              <Link
                to={{
                  //Views the data inputed into the form with the correct ID
                  //Pathname
                  //@ forms is the name after the - in micro
                  //@ view is the file name in the folder
                  pathname: `/profile/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="View"
                  btnIcon="visibility"
                  style={{
                    background: context.user.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>
              {(context.user.agent_id === data[dataIndex].manager_id ||
                (context.user.line_manager && data[dataIndex].manager_id !== null && !data[dataIndex].super_admin) 
                  || context.user.super_admin) && (
                    <>
                    <Link
                      to={{
                        pathname: `/profile/edit/${data[dataIndex].id}`,
                        state: { fromIndex: true, type: "lineManager" },
                      }}
                    >
                      <AppButton
                        text="edit"
                        btnIcon="edit"
                        style={{
                          background: context.user.theme.sidebar.background,
                          color: "white",
                          marginLeft: 10,
                        }}
                      />
                    </Link>
                    {lockButton}
                    <Link
                      to={{
                        pathname: `/profile/edit/applications`,
                        state: { uuid: data[dataIndex].uuid }
                      }}
                    >
                      <AppButton
                        text="apps"
                        btnIcon="apps"
                        style={{
                          background: context.user.theme.sidebar.background,
                          color: "white",
                          marginLeft: 10
                        }}
                      />
                    </Link>
                    </>
                  )}
            </>
          );
        },
      },
    },
  ];

  const options = {};

  return (
    <>
      <div className="container">
        {( context.user.line_manager || context.user.super_admin ) && (
          <Card>
            <CardContent>
              
                <Link
                  to={{
                    pathname: `/user/create`
                  }}
                >
                  <AppButton
                    text="New User"
                    btnIcon="personAdd"
                    style={{
                      background: context.user.theme.sidebar.background,
                      color: "white",
                      marginRight: "5px"
                    }}
                  />
                </Link>

              <Link
                to={{
                  pathname: `/positions`
                }}
              >
                <AppButton
                  text="Positions"
                  btnIcon="list"
                  style={{
                    background: context.user.theme.sidebar.background,
                    color: "white"
                  }}
                />
              </Link>
            </CardContent>
          </Card>
        )}

        <FilterTable columns={columns} data={data} options={options} />
      </div>
    </>
  );
}
