import React, { useState } from "react";
import { Button, CircularProgress  } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { storeDocuments } from "../../../../api/documents"
import { ticketDocumentStore } from "../../../../api/ticket";
import FileBase64 from "react-file-base64";
import { supportAppUUID } from "../../../../config";

export default function AttachmentForm(props) {
    const [uploadData, setUploadData] = useState({});
    const [uploading, setUploading] = useState(false);

    const handleUpload = async (e) => {
        setUploadData({
            application_ref: supportAppUUID,
            documents: e,
            public: 0
        });
    };

    const handleSave = async () => {
        setUploading(true);
        if( typeof uploadData.documents === undefined ) {
            alert("Please Select a File First");
            setUploading(false);
        }

        const res = await storeDocuments(uploadData);

        // Create TicketDocument Records in TicketAPI
        if( Array.isArray( res.data ) )
        {
            res.data.map(async (doc) => {
                var payload = {
                    "document_id": doc.id,
                    "document_name": doc.name,
                    "ticket_id": props.ticket_id
                }
                const docRes = await ticketDocumentStore(payload);
            })
        }
        setUploading(false);
        window.location.reload(true);
    }

    return (
        <>
            <div className="form-row">
                <div className="form-group col-lg-4">
                    <FileBase64 multiple={true} onDone={(e) => handleUpload(e)} />
                    <i>Max Size 5MB</i>
                </div>
                <div className="form-group col-lg-4">
                    <Button
                        disabled={uploading}
                        variant="contained"
                        onClick={() => {
                            handleSave();
                        }}
                        color="success"
                    >
                    {uploading ? (
                        <CircularProgress color="success" size={24} />
                    ) : (
                        <AddIcon/>
                    )}
                    </Button>
                </div>
            </div>
        </>
    )
}