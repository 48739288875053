import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../services/AuthContext";
import config from "../../config";

import ApplicationsForm from "../../components/modules/profile/applicationsForm";
import ApiLoader from "../../components/common/ApiLoader";

import { getRegistrations, alterRegistrations } from "../../api/profile";

export default function ProfileApps(props) {
    const [data, setData] = useState({});
    const [appsToAdd, setAppsToAdd] = useState([]);
    const [applications, setApplications] = useState([{}]);
    const [loading, setLoading] = useState({ status: false, data: "" });
    const context = useContext(AuthContext);

    let indexState;

    if( props.location.state ) {
        indexState = props.location.state;
    }

    const getApplications = async () => {
      const apps = context.body.users[0].registrations;
  
      var app_data = [];
  
      await Promise.all(
        apps.map(async (item, key) => {
          const data = await fetch(
            `${config.appURL}/application?applicationId=${item.applicationId}`,
            {
              credentials: "include", // fetch won't send cookies unless you set credentials
            }
          );
  
          const res = await data.json();
          app_data.push(res.application);
        })
      );
  
      const filterNames = ["portal", "sandbox", "support"];
  
      const filtedArray = app_data.filter(
        (item) => !filterNames.includes(item.name)
      );
  
      setApplications(filtedArray);
    };

    /**
     * Construct an array of all the app_ids the selected user is currently added to. Construct an assosiative array of app_id to app_id to make it easier to query the array and delete ids as they are altered
     */
    const getUserApplications = async () => {
        const apps = await getRegistrations( indexState.uuid );

        const ids = [];
        apps.data.map( app => { ids[app.applicationId] = true } );

        console.log(ids);

        setAppsToAdd(ids);
    };

    const handleChange = async (app_uuid, checked) => {
      let temp = appsToAdd;
      if(checked)
      {
        temp[app_uuid] = true;
      }
      else
      {
        delete temp[app_uuid];
      }

      await setAppsToAdd([]);
      setAppsToAdd(temp);
    }

    const handleSave = () => {
      let add = Object.keys( appsToAdd );
      let remove = [];

      // All the app in the list that are not in the add list are to be removed
      applications.forEach( app => {
        if(!appsToAdd[app.id])
        {
          remove[app.id] = app.id;
        }
      })
      remove = Object.keys( remove );

      let payload = {
        "add": add,
        "remove": remove,
        "user_uuid": indexState.uuid
      }

      setLoading({ status: true, data: "Updating Applications, Please Wait..."});
      const res = alterRegistrations(payload);
      setLoading({ status: true, data: "Applications Updated" });

      setTimeout(() => {
        window.history.go(-1);
        setLoading({ status: false });
    }, 4000);
    }

    useEffect(() => {
      getUserApplications();
      getApplications();
    }, []);
    
    return applications && appsToAdd ? (
        <>
            <ApiLoader status={loading.status} data={loading.data} />

            <ApplicationsForm
              data={data}
              applications={applications}
              appsToAdd={appsToAdd}
              handleChange={handleChange}
              handleSave={handleSave}
            />
        </>
    ) : (
        <>
            <p>Loading Applications</p>
        </>
    );
}

