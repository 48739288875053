/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */
import config from "../config";

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json11
 */

const getDocumentsByCustomer = async (id) => {
  const data = await fetch(
    `${config.appURL}/api/documents/byCustomer?id=${id}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const storeDocuments = async (obj) => {
  const data = await fetch(`${config.appURL}/api/documents/storeDocuments`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLink = async (id) => {
  const data = await fetch(`${config.appURL}/api/documents/getLink?id=${id}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export { getDocumentsByCustomer, storeDocuments, getLink };
