import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Button,
  CircularProgress,
  IconButton,
  Slide,
} from "@material-ui/core";

import ImageCropper from "./ImageCropper";
import FileBase64 from "react-file-base64";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default function ImageDialog({ open, close, user }) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => close()}
        TransitionComponent={Transition}
        style={{ maxWidth: "1000px!important", minWidth: "500px!important" }}
        maxWidth="lg"
      >
        <DialogTitle>Profile Picture Uploader</DialogTitle>
        <ImageCropper user={user} />

        <DialogActions>
          <Button onClick={() => close()} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
