import React, { useEffect } from "react";
import { useState, useContext } from "react";
import FilterTable from "../../components/common/FilterTable";
import { Link } from "react-router-dom";
import AppButton from "../../components/common/AppButton";
import AuthContext from "../../services/AuthContext";
import {
    getPositions,
    getPositionsRestoreIndex,
    deletePosition,
    restorePosition
} from "../../api/services/finance/positions";
import { Card, CardContent } from "@material-ui/core";

export default function PositionIndex() {
    const [data, setData] = useState([{}]);
    const [restore, setRestore] = useState(false);

    const context = useContext(AuthContext);

    const getIndexData = async () => {
        const res = await getPositions();

        setRestore(false);
        setData(res.data);
    }

    const getRestoreIndexData = async () => {
        const res = await getPositionsRestoreIndex();

        setRestore(true);
        setData(res.data);
    }

    const restorePos = async (position_id) => {
        const res = await restorePosition(position_id);
    }

    useEffect(() => {
        getIndexData();
    }, []);
    
    const columns = [
        { name: "position_id", label: "ID" },
        { name: "name", label: "name" },
        { name: "description", label: "Description" },
        { name: "rate", label: "rate" },
        {
            name: "",
            label: "Action",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return !restore ? (
                        <>
                        <Link
                            to={{
                                pathname: `/position/edit/${data[dataIndex].position_id}`
                            }}
                        >
                            <AppButton
                                text="Edit"
                                btnIcon="edit"
                                style={{
                                    background: context.user.theme.sidebar.background,
                                    color: "white",
                                    marginLeft: 10
                                }}
                            />
                        </Link>
                        </>
                    ) : (
                        <AppButton
                            text="Restore"
                            btnIcon="restore"
                            style={{
                                background: "green",
                                color: "white",
                                marginLeft: 10
                            }}
                            onClick={ () => {
                                restorePos(data[dataIndex].position_id);
                                getRestoreIndexData();
                            }}
                        />
                    )
                }
            }
        }
    ];

    const options = !restore ? {
        onRowsDelete : (rows) => {
            rows.data.map((row) => {
                deletePosition(data[row.index].position_id);
            });
        }
    } : {
        onRowsDelete: false
    };

    const restoreButton = !restore ? (
        <>
            <AppButton
                text="Restore"
                btnIcon="restore"
                style={{
                    background: "green",
                    color: "white",
                    marginLeft: "5px"
                }}
                onClick={() => {
                    getRestoreIndexData()
                }}
            />
        </>
    ) : (
        <>
            <AppButton
                text="Return"
                btnIcon="article"
                style={{
                    background: "grey",
                    color: "white",
                    "margin-left": "10px"
                }}
                onClick={() => {
                    getIndexData()
                }}
            />
        </>
    );

    return (
        <>
            <div className="container">
                <Card>
                    <CardContent>
                        <div className="row">
                        <Link
                            to={{
                                pathname: `/position/create`
                            }}
                        >
                            <AppButton
                                text="New Position"
                                btnIcon="add"
                                style={{
                                    background: context.user.theme.sidebar.background,
                                    color: "white",
                                    "margin-left": "10px"
                                }}
                            />
                        </Link>
                        {restoreButton}
                        <Link
                            to={{
                                pathname: `\profiles`
                            }}
                        >
                            <AppButton
                                text="Users"
                                btnIcon="people"
                                style={{
                                    background: context.user.theme.sidebar.background,
                                    color: "white",
                                    marginLeft: "5px"
                                }}
                            />
                        </Link>
                    </div>
                    </CardContent>    
                </Card>

                <FilterTable columns={columns} data={data} options={options} />
            </div>
        </>
    )
}