import React, { useContext, useState, useEffect } from "react";
import ClientForm from "../../components/modules/clients/ClientForm";
import { getClients, updateClient } from "../../api/clients";
import AuthContext from "../../services/AuthContext";
import { getLongLat } from "../../api/postcode";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ApiLoader from "../../components/common/ApiLoader";
import AppButton from "../../components/common/AppButton";
import PortalForm from "../../components/modules/clients/PortalForm";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DocumentUploader from "../../components/modules/clients/DocumentUploader";
import { storeDocuments } from "../../api/documents";

export default function ClientEdit(props) {
  const context = useContext(AuthContext);
  const access = context.user.access;

  const [form, setForm] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [documents, setDocuments] = useState(false);
  const [errors, setErrors] = useState({});

  const checkBackup = async () => {
    if (props.location.state) {
      setForm(props.location.state.backup);
      localStorage.removeItem("cForm");
    }
  };
  const getClient = async () => {
    var clientId = props.match.params.id !== undefined ? props.match.params.id : context.user.client;
    if ((access.clients["A"] && access.clients["U"]) || (clientId == context.user.client)) {
      const client = await getClients(clientId);
      setForm(client);
    }
  };

  const setAddressValue = (e, id) => {
    form.client_site[id][e.target.name] = e.target.value;
    setUpdate(!update);
  };

  const addAddress = () => {
    const newData = { ...form };
    newData.client_site.push({ site_contact: [] });
    setForm(newData);
  };

  const onSubmit = async () => {
    setLoading({
      status: !loading.status,
      data: "Saving your record, Please Wait....",
    });

    var res = await updateClient(form);

    console.log( "response", res );

    if (documents) {
      await storeDocuments({
        documents: documents,
        customer_id: props.match.params.id,
      });
    }

    setLoading({ status: true, data: "Save Success!, Please Wait...." });
    setTimeout(() => {
      setLoading({ status: false });
    }, 3000);
  };

  const onChange = (e) => {
    form[e.target.name] = e.target.value;
    setUpdate(!update);
  };
  const onToggle = (e) => {
    form[e.target.name] = e.target.checked;
    setUpdate(!update);
  };

  const upload = (e) => {
    setDocuments(e);
  };

  const addSiteDocs = (e, site) => {
    const data = { ...form };
    data.client_site[site]["site_docs"] = e;
    setForm(data);
  };

  const getGeoLocation = async (e, index) => {
    const postcode = e.target.value;
    const data = await getLongLat(postcode.replace(" ", ""));
    const newData = { ...form };
    if (data.status === 200) {
      newData.client_site[index].postcode = postcode;
      newData.client_site[index].site_longitude = data.result.longitude;
      newData.client_site[index].site_latitude = data.result.latitude;
      setForm(newData);
    }
  };

  const removeContact = (site, contact) => {
    const data = { ...form };
    data.client_site[site].site_contact[contact]["delete"] = 1;
    setForm(data);
  };

  const cancelRemoveSite = (site) => {
    const data = { ...form };
    delete data.client_site[site]["delete"];
    setForm(data);
  };

  const removeSite = (site) => {
    const data = { ...form };
    data.client_site[site]["delete"] = 1;
    setForm(data);
  };

  const cancelRemove = (site, contact) => {
    const data = { ...form };
    delete data.client_site[site].site_contact[contact]["delete"];
    setForm(data);
  };

  const addContact = (site) => {
    const data = { ...form };
    data.client_site[site].site_contact.push({
      site_contact_name: "",
      site_contact_tel: "",
      site_contact_extension: undefined,
      site_contact_mobile: "",
      site_contact_email: "",
      site_contact_role: "",
    });
    setForm(data);
  };

  const setContactValue = (e, index, index2) => {
    const newData = { ...form };
    newData.client_site[index].site_contact[index2][e.target.name] =
      e.target.value;
    setForm(newData);
  };

  useEffect(() => {
    checkBackup();
    getClient();
  }, []);

  const [menu, setMenu] = useState(0);

  if (form) {
    return (
      <div className="container">
        <ApiLoader status={loading.status} data={loading.data} color={"blue"} />
        <div className="block">
          <div className="row">

            
            {access.clients["A"] && access.clients["U"] ? (
            <div className="col-lg-8">
              <div style={{ display: "inline-block" }}>
                <Tabs
                  value={menu}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(e, val) => setMenu(val)}
                  aria-label="disabled tabs example"
                >
                  <Tab label="Client" />
                  <Tab
                    label="Documents"
                    disabled={form.portal_access ? false : true}
                  />
                  <Tab
                    label="Portal Credentials"
                    disabled={form.portal_access ? false : true}
                  />
                </Tabs>
              </div>
              <div style={{ display: "inline-block", paddingLeft: 10 }}>
                <FormControlLabel
                  control={
                    <Switch
                      name="portal_access"
                      checked={form.portal_access}
                      onChange={(e) => onToggle(e)}
                      color="primary"
                    />
                  }
                  label="Portal Access"
                />
              </div>
            </div>
            ) : (
            <div className="col-lg-8">
              <></>
            </div>
            )}
            

            <div className="col-lg-4" style={{ textAlign: "right" }}>
              <AppButton
                text="save"
                onClick={() => onSubmit()}
                btnIcon="save"
                style={{ color: "white", background: "#35d835" }}
              />
            </div>
          </div>
        </div>

        {menu === 0 && (
          <ClientForm
            data={form}
            context={context}
            type="edit"
            errors={errors}
            upload={upload}
            onChange={onChange}
            addAddress={addAddress}
            setAddressValue={setAddressValue}
            removeContact={removeContact}
            cancelRemove={cancelRemove}
            addContact={addContact}
            getGeoLocation={getGeoLocation}
            setContactValue={setContactValue}
            removeSite={removeSite}
            cancelRemoveSite={cancelRemoveSite}
            addSiteDocs={addSiteDocs}
          />
        )}

        {menu === 1 && (
          <DocumentUploader
            documents={documents}
            setDocuments={setDocuments}
            customer_id={props.match.params.id}
          />
        )}

        {menu === 2 && <PortalForm form={form} setForm={setForm} />}
      </div>
    );
  } else {
    return <p>Loading</p>;
  }
}
