/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */
import config from "../config";

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */

const getTicketIndex = async (id = false) => {
  const data = await fetch(
    `${config.appURL}/api/ticket/get?id=${id}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getTicketShow = async (id = false) => {
  const data = await fetch(
    `${config.appURL}/api/ticket/getSingle?id=${id}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ POST
 * @param  \ obj
 * @return \ json
 */

const saveTicket = async (obj) => {
  const data = await fetch(`${config.appURL}/api/ticket/create`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ PUT
 * @param  \ obj
 * @return \ json
 */

const updateTicket = async (id, obj) => {
  const data = await fetch(
    `${config.appURL}/api/ticket/update?id=${id}`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */

 const ticketDocumentIndex = async (payload) => {
  const data = await fetch(`${config.appURL}/api/ticket/document/index`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const ticketDocumentStore = async (payload) => {
  const data = await fetch(`${config.appURL}/api/ticket/document/store`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

export { getTicketIndex, getTicketShow, saveTicket, updateTicket, ticketDocumentIndex, ticketDocumentStore };
