import {
  Avatar,
  Button,
  InputLabel,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import FileBase64 from "react-file-base64";

import AppButton from "../../common/AppButton";
import "./styles.css";

export default function ProfileForm({
  data,
  handleSave,
  handleChange,
  handlePasswordChangeClick,
  type,
  upload,
  personnel,
  lineManagersData,
  groups,
  setOpenImageCropper,
}) {
  return (
    <>
      <div className="container">
        <div className="block">
          <div className="container-fluid">
            <div className="form-row">
              <div className="form-group col-lg-10">
                <h3>Main Information</h3>
              </div>
              <div className="form-group col-lg-2">
                {type !== "view" && (
                  <AppButton
                    text="save data"
                    btnIcon="save"
                    style={{
                      color: "white",
                      background: "blue",
                    }}
                    onClick={() => {
                      handleSave();
                    }}
                  />
                )}
              </div>
            </div>

            <div className="info-container">
              <div className="photo-container" align="center">
                <div
                  style={{
                    backgroundImage: "url(" + data.image_url + ")",
                  }}
                  className="rounded-logo"
                ></div>

                {type != "view" && type != "lineManager" && (
                  //<FileBase64 multiple={false} onDone={(e) => upload(e)} />
                  <Button
                    onClick={() => setOpenImageCropper(true)}
                    variant="outlined"
                  >
                    Change Image
                  </Button>
                )}
              </div>

              <div className="text-container">
                <div style={{ paddingBottom: "10px" }}>
                  <InputLabel>Name</InputLabel>
                  <TextField
                    name="name"
                    type="text"
                    value={data.name ? data.name : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "view"}
                  />
                </div>
                <InputLabel>Portal Email</InputLabel>
                <TextField
                  disabled={true}
                  name="work_email"
                  type="text"
                  value={data.work_email ? data.work_email : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  fullWidth={true}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-3">
                <InputLabel>Business Tel:</InputLabel>
                <TextField
                  name="work_tel"
                  type="number"
                  value={data.work_tel ? data.work_tel : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  fullWidth={true}
                  disabled={type === "view"}
                />
              </div>
              <div className="form-group col-lg-3">
                <InputLabel>Ext:</InputLabel>
                <TextField
                  name="extension"
                  type="number"
                  value={data.extension ? data.extension : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  fullWidth={true}
                  disabled={type === "view"}
                />
              </div>
              <div className="form-group col-lg-3">
                <InputLabel>Business Mobile</InputLabel>
                <TextField
                  name="work_mobile"
                  type="text"
                  value={data.work_mobile ? data.work_mobile : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  fullWidth={true}
                  disabled={type === "view"}
                />
              </div>
              <div className="form-group col-lg-3">
                <InputLabel>Job Role</InputLabel>
                <TextField
                  name="position"
                  type="text"
                  value={data.position ? data.position : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  fullWidth={true}
                  disabled={type === "view"}
                />
              </div>
              <div className="form-group col-lg-6">
                <InputLabel>Line Manager </InputLabel>
                <Select
                  disabled={type !== "lineManager"}
                  required
                  fullWidth
                  name="manager_id"
                  value={data.manager_id ? data.manager_id : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                  {lineManagersData &&
                    lineManagersData.map((e, key) => (
                      <MenuItem key={key} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>

              <div className="form-group col-lg-6">
                <InputLabel>Group </InputLabel>
                <Select
                  disabled={type !== "lineManager"}
                  required
                  fullWidth
                  name="group_name"
                  value={data.group_name ? data.group_name : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                  {groups &&
                    groups.map((e, key) => (
                      <MenuItem key={key} value={e.group_name}>
                        {e.group_name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <div className="form-group col-lg-12">
                <InputLabel>Bio </InputLabel>
                <TextField
                  name="bio"
                  type="text"
                  multiline
                  value={data.bio ? data.bio : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  fullWidth={true}
                  disabled={type === "view" || type === "lineManager"}
                />
              </div>
            </div>
          </div>
        </div>

        {type !== "view" && (
          <div className="block">
            <div className="container-fluid">
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <h3>Personal Details</h3>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-4">
                  <InputLabel>Personal Mobile</InputLabel>
                  <TextField
                    name="home_tel"
                    type="number"
                    value={data.home_tel ? data.home_tel : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <InputLabel>Personal Email</InputLabel>
                  <TextField
                    name="home_email"
                    type="text"
                    value={data.home_email ? data.home_email : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <InputLabel>DOB</InputLabel>
                  <TextField
                    name="dob"
                    type="date"
                    value={data.dob ? data.dob : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>

                <div className="form-group col-lg-3">
                  <InputLabel>Postcode</InputLabel>
                  <TextField
                    name="home_postcode"
                    type="text"
                    value={data.home_postcode ? data.home_postcode : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <InputLabel>Street</InputLabel>
                  <TextField
                    name="home_street"
                    type="text"
                    value={data.home_street ? data.home_street : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>

                <div className="form-group col-lg-3">
                  <InputLabel>Town</InputLabel>
                  <TextField
                    name="home_town"
                    type="text"
                    value={data.home_town ? data.home_town : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>

                <div className="form-group col-lg-3">
                  <InputLabel>House Number</InputLabel>
                  <TextField
                    name="home_no"
                    type="number"
                    value={data.home_no ? data.home_no : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ marginTop: "10px" }}
                    onClick={() => {
                      handlePasswordChangeClick();
                    }}
                    disabled={type === "lineManager"}
                  >
                    Change Password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {type !== "view" && (
          <div className="block">
            <div className="container-fluid">
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <h3>Emergency Contact</h3>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <InputLabel>Name</InputLabel>
                  <TextField
                    name="nok_name"
                    type="text"
                    value={data.nok_name ? data.nok_name : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <InputLabel>Relationship</InputLabel>
                  <TextField
                    name="nok_relationship"
                    type="text"
                    value={data.nok_relationship ? data.nok_relationship : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <InputLabel>Telephone</InputLabel>
                  <TextField
                    name="nok_tel"
                    type="number"
                    value={data.nok_tel ? data.nok_tel : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <InputLabel>Email</InputLabel>
                  <TextField
                    name="nok_email"
                    type="text"
                    value={data.nok_email ? data.nok_email : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth={true}
                    disabled={type === "lineManager"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
