import React, { useContext, useEffect, useState } from "react";
import ClientForm from "../../components/modules/clients/ClientForm";
import { getClients } from "../../api/clients";
import AuthContext from "../../services/AuthContext";
import { Link } from "react-router-dom";
import AppButton from "../../components/common/AppButton";
// TEST FOR GTI HOOK

export default function ClientView(props) {
  const context = useContext(AuthContext);
  const access = context.user.access;

  const [data, setData] = useState(false);
  const onChange = (e) => {};

  const upload = (e) => {};

  const addAddress = () => {};

  // on tuesday, I think that the problem the useEffect is not running again?
  const getClient = async () => {
    if ((access.clients["A"] && access.clients["R"]) || (props.match.params.id == context.user.client)) {
      const client = await getClients(props.match.params.id);
      console.log("the client themselves",client);
      setData(client);
    }
  };

  useEffect(() => {
    getClient();
  }, []);

  if (data) {
    return (
      <div className="container">

        <div className="block">
          <div className="row">
            <div className="col-lg-8">
              <></>
            </div>

            <div className="col-lg-4" style={{ textAlign: "right" }}>
              <Link to={`/my-details/update/${context.user.client}`}>
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: "blue",
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
              </Link>
            </div>
          </div>
        </div>

        <ClientForm
          data={data}
          onChange={onChange}
          errors={{}}
          type="view"
          addAddress={addAddress}
          upload={upload}
        />
      </div>
    );
  } else {
    return <p>Loading</p>;
  }
}
