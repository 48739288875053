import React from "react";
import TextField from "@material-ui/core/TextField";
import FileBase64 from "react-file-base64";
import AppButton from "../../common/AppButton";
import ClientReports from "../../common/ClientReports";

export default function ClientForm(props) {
  const doc = (documents) => {};
  return (
    <div>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-5">
              <h4>Client</h4>

              {props.data.file_path ? (
                <img src={props.data.file_path} className="client_logo" />
              ) : (
                <>
                  {props.type !== "view" && !props.context.user.client ? (
                    <FileBase64
                      multiple={false}
                      onDone={(e) => props.upload(e)}
                    />
                  ) : (
                    <p>No image provided</p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Client Reference"
                disabled
                value={props.data.ref}
                fullWidth={true}
                helperText={"Client Unique Reference Number"}
              ></TextField>
            </div>

            <div className="form-group col-lg-4">
              <TextField
                label="Client Name (Company Name)"
                value={props.data.client_name}
                name="client_name"
                fullWidth={true}
                error={props.errors.client_name ? true : false}
                helperText={
                  props.errors.client_name
                    ? "This field is required"
                    : "Enter the clients company name"
                }
                onChange={(e) => props.onChange(e)}
              ></TextField>
            </div>

            <div className="form-group col-lg-4">
              <TextField
                label="Status"
                value={props.data.client_status}
                name="client_status"
                fullWidth={true}
                helperText={"Accounts current standing"}
                onChange={(e) => props.onChange(e)}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Client Telephone"
                value={props.data.client_tel}
                fullWidth={true}
                name="client_tel"
                error={props.errors.client_tel ? true : false}
                helperText={
                  props.errors.client_name
                    ? "This field is required"
                    : "Enter the clients landline"
                }
                onChange={(e) => props.onChange(e)}
              ></TextField>
            </div>

            <div className="form-group col-lg-4">
              <TextField
                label="Client Primary Email"
                value={props.data.client_email}
                fullWidth={true}
                name="client_email"
                helperText={"Main company email address"}
                onChange={(e) => props.onChange(e)}
              ></TextField>
            </div>

            <div className="form-group col-lg-4">
              <TextField
                label="Website Address"
                value={props.data.client_website}
                fullWidth={true}
                name="client_website"
                helperText={"Company Website Address"}
                onChange={(e) => props.onChange(e)}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-2">
              <TextField
                label="House Number / Unit"
                value={props.data.client_house_number}
                fullWidth={true}
                name={"client_house_number"}
                helperText={"House or unit number"}
                onChange={(e) => props.onChange(e)}
              ></TextField>
            </div>
            <div className="form-group col-lg-3">
              <TextField
                label="First Line Address"
                value={props.data.client_first_line}
                fullWidth={true}
                name="client_first_line"
                helperText={"e.g example road"}
                onChange={(e) => props.onChange(e)}
              ></TextField>
            </div>
            <div className="form-group col-lg-3">
              <TextField
                label="Town / County"
                value={props.data.client_town}
                fullWidth={true}
                name="client_town"
                helperText={"Client town or county"}
                onChange={(e) => props.onChange(e)}
              ></TextField>
            </div>
            <div className="form-group col-lg-3">
              <TextField
                label="Postcode"
                value={props.data.client_postcode}
                fullWidth={true}
                name="client_postcode"
                helperText={"Client postcode"}
                onChange={(e) => props.onChange(e)}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-2">
              <TextField
                id="time"
                label="Opening Time"
                type="time"
                value={props.data.client_open}
                name="client_open"
                required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                fullWidth={true}
                onChange={(e) => props.onChange(e)}
              />
            </div>
            <div className="form-group col-lg-2">
              <TextField
                id="time"
                label="Closing Time"
                type="time"
                value={props.data.client_closed}
                name="client_closed"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                fullWidth={true}
                onChange={(e) => props.onChange(e)}
              />
            </div>
            <div className="form-group col-lg-8">
              <TextField
                id="time"
                label="Special Instructions"
                multiline
                value={props.data.client_special}
                name="client_special"
                rowsMax={3}
                fullWidth={true}
                onChange={(e) => props.onChange(e)}
              />
            </div>
          </div>
        </div>
      </div>

      {props.data.client_site &&
        props.data.client_site.map((address, index) => {
          return (
            <div className="block">
              <div className="container-fluid" style={{ paddingTop: 15 }}>
                <h4
                  style={
                    address.delete && address.delete === 1
                      ? { opacity: "0.5" }
                      : {}
                  }
                >
                  Site {index + 1}
                </h4>
                {props.type !== "view" ? (
                  <div className="form-row">
                    <div className="form-group col-lg-12">
                      {address.delete && address.delete === 1 ? (
                        <div onClick={() => props.cancelRemoveSite(index)}>
                          <AppButton
                            text="Cancel"
                            btnIcon="close"
                            stylename="secondary"
                          />
                        </div>
                      ) : (
                        <div onClick={() => props.removeSite(index)}>
                          {
                            !props.context.user.client && (
                              <AppButton
                                text="Remove Site"
                                btnIcon="delete"
                                stylename="secondary"
                              />
                            )
                          }

                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={
                    address.delete && address.delete === 1
                      ? { opacity: "0.5" }
                      : {}
                  }
                >
                  <div className="form-row">
                    <div className="form-group col-lg-2">
                      <TextField
                        label="House Number / Unit"
                        value={address.site_house_number}
                        fullWidth={true}
                        name={"site_house_number"}
                        helperText={"House or unit number"}
                        onChange={(e) => props.setAddressValue(e, index)}
                      ></TextField>
                    </div>
                    <div className="form-group col-lg-3">
                      <TextField
                        label="First Line Address"
                        value={address.site_first_line}
                        fullWidth={true}
                        name="site_first_line"
                        helperText={"e.g example road"}
                        onChange={(e) => props.setAddressValue(e, index)}
                      ></TextField>
                    </div>
                    <div className="form-group col-lg-3">
                      <TextField
                        label="Town / County"
                        value={address.site_town}
                        fullWidth={true}
                        name="site_town"
                        helperText={"Client town or county"}
                        onChange={(e) => props.setAddressValue(e, index)}
                      ></TextField>
                    </div>
                    <div className="form-group col-lg-3">
                      <TextField
                        label="Postcode"
                        defaultValue={address.site_postcode}
                        fullWidth={true}
                        name="site_postcode"
                        helperText={"Client postcode"}
                        onChange={(e) => props.getGeoLocation(e, index)}
                      ></TextField>
                    </div>
                    <div className="form-group col-lg-3">
                      <TextField
                        label="Longitude"
                        value={address.site_long}
                        fullWidth={true}
                        name="site_long"
                        helperText={
                          "Longitude for app to detect on site arrival"
                        }
                        onChange={(e) => props.setAddressValue(e, index)}
                      ></TextField>
                    </div>
                    <div className="form-group col-lg-3">
                      <TextField
                        label="Latitude"
                        value={address.site_lat}
                        fullWidth={true}
                        name="site_lat"
                        helperText={
                          "Latitude for app to detect on site arrival"
                        }
                        onChange={(e) => props.setAddressValue(e, index)}
                      ></TextField>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-lg-2">
                      <TextField
                        id="time"
                        label="Opening Time"
                        type="time"
                        value={address.site_open}
                        name="site_open"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        fullWidth={true}
                        onChange={(e) => props.setAddressValue(e, index)}
                      />
                    </div>
                    <div className="form-group col-lg-2">
                      <TextField
                        id="time"
                        label="Closing Time"
                        type="time"
                        value={address.site_closed}
                        name="site_closed"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        fullWidth={true}
                        onChange={(e) => props.setAddressValue(e, index)}
                      />
                    </div>
                    <div className="form-group col-lg-8">
                      <TextField
                        id="time"
                        label="Special Instructions"
                        multiline
                        value={address.site_special}
                        name="site_special"
                        rowsMax={3}
                        fullWidth={true}
                        onChange={(e) => props.setAddressValue(e, index)}
                      />
                    </div>
                  </div>
                  {address.site_contact &&
                    address.site_contact.map((contact, index2) => {
                      return (
                        <div>
                          <div
                            style={
                              contact.delete === 1 ? { opacity: "0.5" } : {}
                            }
                          >
                            <h4>Contact {index2 + 1}</h4>
                            {props.type !== "view" ? (
                              <div className="form-row">
                                <div className="form-group col-lg-12">
                                  {contact.delete === 1 ? (
                                    <div
                                      onClick={() =>
                                        props.cancelRemove(index, index2)
                                      }
                                    >
                                      <AppButton
                                        text="Cancel"
                                        btnIcon="close"
                                        stylename="secondary"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        props.removeContact(index, index2)
                                      }
                                    >
                                      {
                                        !props.context.user.client && (
                                          <AppButton
                                            text="Remove Contact"
                                            btnIcon="delete"
                                            stylename="secondary"
                                          />
                                        )
                                      }
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="form-row">
                              <div className="form-group col-lg-4">
                                <TextField
                                  label="Site Contact Name"
                                  value={contact.site_contact_name}
                                  fullWidth={true}
                                  name="site_contact_name"
                                  helperText={"Site primary contact name"}
                                  onChange={(e) =>
                                    props.setContactValue(e, index, index2)
                                  }
                                ></TextField>
                              </div>
                              <div className="form-group col-lg-4">
                                <TextField
                                  label="Site Contact Telephone"
                                  value={contact.site_contact_tel}
                                  fullWidth={true}
                                  name="site_contact_tel"
                                  helperText={"Site Contact Landline Number"}
                                  onChange={(e) =>
                                    props.setContactValue(e, index, index2)
                                  }
                                ></TextField>
                              </div>
                              <div className="form-group col-lg-4">
                                <TextField
                                  label="Extension Number"
                                  value={contact.site_extension_number}
                                  fullWidth={true}
                                  name="site_contact_extension_number"
                                  helperText={"Site extension number"}
                                  onChange={(e) =>
                                    props.setContactValue(e, index, index2)
                                  }
                                ></TextField>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-lg-4">
                                <TextField
                                  label="Site Contact Mobile"
                                  value={contact.site_contact_mob}
                                  fullWidth={true}
                                  name="site_contact_mob"
                                  helperText={"Site primary contact mobile"}
                                  onChange={(e) =>
                                    props.setContactValue(e, index, index2)
                                  }
                                ></TextField>
                              </div>
                              <div className="form-group col-lg-4">
                                <TextField
                                  label="Site Contact Email Address"
                                  value={contact.site_contact_email}
                                  fullWidth={true}
                                  name="site_contact_email"
                                  helperText={"Site primary email address"}
                                  onChange={(e) =>
                                    props.setContactValue(e, index, index2)
                                  }
                                ></TextField>
                              </div>
                              <div className="form-group col-lg-4">
                                <TextField
                                  label="Site Contact Role"
                                  value={contact.site_contact_role}
                                  fullWidth={true}
                                  name="site_contact_role"
                                  helperText={"Site contact job title / role"}
                                  onChange={(e) =>
                                    props.setContactValue(e, index, index2)
                                  }
                                ></TextField>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {props.type !== "view" ? (
                    <div className="form-row">
                      <div className="form-group col-lg-12">
                        <div onClick={() => props.addContact(index)}>
                          <AppButton
                            text="Add Contact"
                            btnIcon="add"
                            stylename="primary"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div
                  style={
                    address.delete && address.delete === 1
                      ? { opacity: "0.5" }
                      : {}
                  }
                >
                  <h4>Site Documents</h4>
                  {props.type !== "view" && (
                    <FileBase64
                      multiple={true}
                      onDone={(e) => props.addSiteDocs(e, index)}
                    />
                  )}

                  {address.documents &&
                    address.documents.map((item) => {
                      return (
                        <div>
                          <a href={item.path}>{item.name}</a>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}

      {props.type !== "view" ? (
        <div className="form-row">
          <div className="form-group col-lg-12">
            <div onClick={() => props.addAddress()} style={{ marginTop: 20 }}>
              <AppButton text="Add Site" btnIcon="add" stylename="primary" />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
