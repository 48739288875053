import AppButton from "../../common/AppButton";
import React from "react";
import { InputLabel, TextField, Select, MenuItem } from "@material-ui/core";

export default function PositionForm({
    data,
    handleSave,
    handleChange,
    groups,
    handleGroupChange,
    editFlag
}) {
    const departmentSelect = !editFlag ? (
        <>
            <div className="form-row">
                <div className="form-group col-lg-6">
                    <InputLabel>Department:</InputLabel>
                    <Select
                        name="user_group_uuid"
                        required
                        fullWidth
                        value={data.group ? data.group : groups[0]}
                        defaultValue=""
                        onChange={(e) => handleGroupChange(e.target.value)}
                    >
                        {groups &&
                        groups.map((e, key) => (
                            <MenuItem key={key} value={e}>
                                {e.group_name}    
                            </MenuItem>
                        ))}   
                    </Select>
                </div>
            </div>
        </>
    ) : (<></>);
    return (groups) ? (
        <>
            <div className="container">
                <div className="block">
                    <div className="container-fluid">
                        <div className="form-row">
                            <div className="form-group col-lg-10">
                                <h3>Position</h3>
                            </div>
                            <div className="form-group col-lg-2">
                                <AppButton
                                    text="Save Position"
                                    btnIcon="add"
                                    style={{
                                        color: "white",
                                        background: "green"
                                    }}
                                    onClick={() => {
                                        handleSave();
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-lg-6">
                                <InputLabel>Name:</InputLabel>
                                <TextField
                                    name="name"
                                    type="text"
                                    value={data.name}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    fullWidth
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <InputLabel>Rate:</InputLabel>
                                <TextField
                                    name="rate"
                                    type="number"
                                    value={data.rate}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-lg-12">
                                <InputLabel>Description:</InputLabel>
                                <TextField
                                    name="description"
                                    type="text"
                                    value={data.description}
                                    multiline
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    fullWidth
                                />
                            </div>
                        </div>

                        {departmentSelect}
                    </div>    
                </div>    
            </div>
        </>
    ) : (
        <>
            <p>Loading Data...</p>
        </>
    )
}