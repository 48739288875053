/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/tenant-service/users
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://tenant-service.rrdevelopment.co.uk/api/users
 */
import config from "../../../config";

/**
 * Tenants Service https://appURL/api/tenant-service/users/
 * @method \ POST
 * @return \ json
 */

const createUser = async (obj) => {
  const data = await fetch(`${config.appURL}/api/tenant-service/user/create`, {
    method: "POST",
    body: JSON.stringify(obj),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
/**
 * Tenants Service https://appURL/api/tenant-service/users/
 * @method \ POST
 * @return \ json
 */

const updateUser = async (obj) => {
  const data = await fetch(`${config.appURL}/api/tenant-service/users/update?id=${obj.id}`, {
    method: "POST",
    body: JSON.stringify(obj),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * Tenants Service https://appURL/api/tenant-service/users/
 * @method \ POST
 * @return \ json
 */

const getUser = async (id) => {
  const data = await fetch(`${config.appURL}/api/tenant-service/users/get?id=${id}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const userShowForContact = async (id) => {
  const data = await fetch(`${config.appURL}/api/tenant-service/users/showForContact?id=${id}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const userShowWithConfig = async (id) => {
  const data = await fetch(`${config.appURL}/api/tenant-service/users/showWithConfig?id=${id}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};
const deleteUser = async (id) => {
  const data = await fetch(`${config.appURL}/api/tenant-service/users/delete?id=${id}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}
const restoreUser = async (id) => {
  const data = await fetch(`${config.appURL}/api/tenant-service/users/restore?id=${id}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

export { createUser, updateUser, getUser, userShowForContact, userShowWithConfig, deleteUser, restoreUser };
