import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { saveTicket } from "../../api/ticket";
import ApiLoader from "../../components/common/ApiLoader";
import AppButton from "../../components/common/AppButton";
import TicketCreateForm from "../../components/modules/tickets/TicketCreateForm";
import AuthContext from "../../services/AuthContext";

export default function TicketsCreate(props) {
    const [data, setData] = useState({});

    const [loading, setLoading] = useState({ status: false, data: false });

    let history = useHistory();

    const context = useContext(AuthContext)

    const handleSave = async () => {
        setLoading({ status: true, data: "Saving Records.. Please Wait !" });
        data['partial'] = 1;
        data['customer_id'] = context.user.client;
        await saveTicket(data)

        setLoading({ status: true, data: "Success, Please Wait ...." });

        setTimeout(() => {
            history.push("/tickets");
        }, 4000);
    };

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };


    const setDocuments = (e) => {
        setData({ ...data, "customer_documents": e });
    }


    return (
        <>
            <div className="container">
                <div className="block" style={{ marginBottom: 10, textAlign: "right" }}>
                    <AppButton
                        text="submit ticket"
                        btnIcon="save"
                        style={{ color: "white", background: "blue" }}
                        onClick={() => handleSave()}
                    />
                </div>
                <TicketCreateForm
                    handleChange={handleChange}
                    data={data}
                    type="create"
                    setDocuments={setDocuments}
                    context={context}
                />
                <ApiLoader color={'blue'} status={loading.status} data={loading.data} />
            </div>


        </>
    );
}
