import React, { useEffect, useState } from "react";
import FileBase64 from "react-file-base64";
import { getDocumentsByCustomer, getLink } from "../../../api/documents";
import AppButton from "../../common/AppButton";

export default function DocumentUploader(props) {
  const [docs, setDocs] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const getDocs = async () => {
    const res = await getDocumentsByCustomer(props.customer_id);
    setDocs(res.data);
  };

  const getDoc = async (id) => {
    setDownloading(id);
    const res = await getLink(id);
    setDownloading(false);
    var win = window.open(res.data, "_blank");
    win.focus();
  };

  console.log(docs);

  useEffect(() => {
    getDocs();
  }, []);

  const data = {
    0: require("../../../assets/image.png"),
    1: require("../../../assets/pdf.png"),
    2: require("../../../assets/excel.png"),
    3: require("../../../assets/word.jpg"),
    4: "fallback",
  };

  return (
    <>
    <div className="container">
      <div className="block" style={{ marginTop: "10px" }}>
        <FileBase64 multiple={true} onDone={(e) => props.setDocuments(e)}/>
        {docs ? (
          <div className="row" style={{ marginTop: "20px" }}>
            {docs.map((item) => {
              return (
                <div className="col-lg-2" style={{ textAlign: "center" }}>
                  <img style={{ width: 100 }} src={data[item.type]} />
                  <div>{item.name}</div>

                  {downloading === item.id ? (
                    <AppButton
                      text="please wait"
                      btnIcon="refresh"
                      style={{ color: "white", background: "#5ce05c" }}
                    />
                  ) : (
                    <AppButton
                      onClick={() => getDoc(item.id)}
                      text="download"
                      btnIcon="download"
                      style={{ color: "white", background: "#5ce05c", marginBottom: "10px" }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <p>No Documents Yet</p>
        )}
      </div>
    </div>
    </>
  );
}
