import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { chatIndexByTicketArray, chatStore } from "../../api/livechat";
import { getTicketShow, updateTicket } from "../../api/ticket";
import ApiLoader from "../../components/common/ApiLoader";
import AppButton from "../../components/common/AppButton";
import Loading from "../../components/common/Loading";
import TicketCreateForm from "../../components/modules/tickets/TicketCreateForm";
import ChatMessageItemCustomer from "./ChatMessageItem";
import ChatMessageItemUser from "./ChatMessageItemUser";
import "./chat.css";
import AuthContext from "../../services/AuthContext";

export default function TicketsEdit(props) {

    const Pusher = require('pusher-js');

    const [data, setData] = useState(false);
    const [chat, setChat] = useState(false);
    const context = useContext(AuthContext);
    const [loading, setLoading] = useState({ status: false, data: false });
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    const handleMessageSend = async () => {
        var obj = {
            chat_id: chat.id,
            body: message,
            is_customer: true,
            username: context.user.name,
            support_email: true,
            company: context.user.client
        }

        const r = await chatStore(obj);
        setMessage("")
    }

    let history = useHistory();

    const handleSave = async () => {

        setLoading({ status: true, data: "Saving Records.. Please Wait !" });

        //new middleware save
        await updateTicket(props.match.params.id, data);

        setLoading({ status: true, data: "Success, Please Wait ...." });

        setTimeout(() => {
            history.push("/tickets");
        }, 4000);
    };


    const getTickets = async () => {
        const data = await getTicketShow(props.match.params.id);
        const chatData = await chatIndexByTicketArray({ reference_array: [props.match.params.id] });
        var pusher = new Pusher('c5350c80e013ebed7175', {
            cluster: 'eu'
        });
        var channel = pusher.subscribe(context.user.tenant_uuid);

        setChat(chatData.data.length > 0 ? chatData.data[0] : chatData.data)
        setData(data.data);
        {
            chatData.data.length > 0 &&
                channel.bind(`chat-ticket-${chatData.data[0].id}`, async function (e) {
                    const chatData = await chatIndexByTicketArray({ reference_array: [props.match.params.id] });
                    setChat(chatData.data[0])
                });
        }
    };

    useEffect(() => {
        getTickets();
    }, []);

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const setDocuments = (e) => {
        setData({ ...data, "customer_documents": e });
    }


    if (data) {
        return (
            <>
                <div className="container">
                    <div className="block" style={{ marginBottom: 10, textAlign: "right" }}>
                        <AppButton
                            text="update ticket"
                            btnIcon="save"
                            style={{ color: "white", background: "blue" }}
                            onClick={() => handleSave()}
                        />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <div className="block" style={{ marginBottom: 10 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.customer_closed}
                                    onChange={(e) => setData({ ...data, "customer_closed": e.target.checked })}
                                    color="primary"
                                />
                            }
                            label={data.customer_closed ? "Please now update your ticket to close" : "Request Ticket Closure"}
                        />
                        </div>
                    </div>
                    <TicketCreateForm
                        handleChange={handleChange}
                        data={data}
                        type="edit"
                        setDocuments={setDocuments}
                        setOpen={setOpen}
                        context={context}
                    />


                    <div className="block" id="chat-window" style={{ height: "100%", padding: 20, boxShadow: "none", overflowY: "scroll", paddingBottom: 90, paddingTop: 10 }}>
                        <p className="chat-title" style={{ marginBottom: 30 }}>Ticket {chat.reference_id}</p>
                        {chat && chat.messages.map((item, key) => {
                            if (!item.is_customer) {
                                return <ChatMessageItemCustomer data={item} />
                            } else {
                                return <ChatMessageItemUser data={item} />
                            }
                        })}

                        <div className="chat-actions">
                            <textarea onChange={(e) => setMessage(e.target.value)} value={message} type="text" placeholder="Type message" className="input-message" />
                            <AppButton text="send" btnIcon="send" onClick={() => handleMessageSend()} style={{ background: '#7f7fff', color: 'white' }} />
                        </div>

                    </div>

                </div>

                <ApiLoader color={'blue'} status={loading.status} data={loading.data} />

            </>
        );
    } else {
        return <Loading color={'white'} />
    }
}
