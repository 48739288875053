import React, { useEffect, useState } from "react";
import { userShowWithConfig } from "./api/services/tenant/users";
import "./index.css";

import Interface from "./navigation/Interface";
import Login from "./pages/login";
import AuthContext from "./services/AuthContext";

const config = require("./config");

export default function App() {
  const [user, setUser] = useState(false);
  const [body, setBody] = useState(false);

  const getUser = async () => {

    const response = await fetch(`${config.appURL}/user`, {
      credentials: "include", // fetch won't send cookies unless you set credentials
    });
    const json = await response.json();

    if (!json.token) {
      window.location.replace(`${config.appURL}/login`);
    } else {
      const data = await userShowWithConfig(json.users[0].id);
      setUser(data.data);
      setBody(json);
    }
  };
  
  useEffect(() => {
    getUser();
  }, []);

  if (body && user) {
    return (
      <AuthContext.Provider
        value={{
          user,
          setUser,
          body,
        }}
      >
        {user ? <Interface /> : <></>}
      </AuthContext.Provider>
    );
  } else {
    return <p>You are not authorised to use this app</p>;
  }
}
