import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { InputLabel } from "@material-ui/core";

export default function ChangePasswordPopup(props) {
  const [data, setData] = useState({});

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleOnSave = () => {
    if (data.new_password === data.re_entered_pass) {
      props.save(data);
    } else {
      alert("Passwords Don't Match");
    }
  };
  return (
    <>
      <div>
        <Dialog
          open={props.open}
          onClose={() => {
            props.close();
          }}
        >
          <DialogContent>
            <>
              <div>
                <InputLabel>Enter Current Password</InputLabel>
                <TextField
                  name="current_password"
                  type="password"
                  value={data.current_password ? data.current_password : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  fullWidth={true}
                />
              </div>
              <div style={{ paddingTop: "10px" }}>
                <InputLabel>Enter New Password</InputLabel>
                <TextField
                  name="new_password"
                  type="password"
                  value={data.new_password ? data.new_password : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  fullWidth={true}
                />
              </div>
              <div style={{ paddingTop: "10px" }}>
                <InputLabel>Re-enter New Password</InputLabel>
                <TextField
                  name="re_entered_pass"
                  type="password"
                  value={data.re_entered_pass ? data.re_entered_pass : ""}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  fullWidth={true}
                />
              </div>
            </>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleOnSave();
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                props.close();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
