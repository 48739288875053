import React, { useCallback, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import AvatarEditor from "react-avatar-editor";
import { Slider, CircularProgress, Button } from "@material-ui/core";
import ApiLoader from "../../common/ApiLoader";
import FileBase64 from "react-file-base64";

import { saveImage } from "../../../api/profile";
import { useHistory } from "react-router-dom";

export default function ImageCropper({ user }) {
  const [scale, setScale] = useState(1);
  const [image, setImage] = useState(false);
  const [baseImage, setBaseImage] = useState(null);
  const [loading, setLoading] = useState({ status: false });
  const canvasRef = useRef(null);

  let history = useHistory();

  const handleSliderChange = (event, newValue) => {
    if (newValue < 1) setScale(1);
    setScale(newValue);
  };

  const handleImageUpload = (e) => {
    setImage(e.base64);
    setBaseImage(e);
  };

  const onClickSave = async () => {
    setLoading({ status: true, data: "Loading Please Wait..." });
    const newObj = {
      uuid: user.id,
      contact_id: user.contact,
      image_path: {
        name: baseImage.name,
        type: baseImage.type,
        size: baseImage.size,
        base64: canvasRef.current.getImage().toDataURL(),
      },
    };

    const res = await saveImage(user.agent_id, newObj);

    console.log("Saved Image Res", res);
    if (res.errors === null) {
      setLoading({ status: true, data: "Save Success Please Wait..." });
      setTimeout(() => {
        history.push("/profile");
      }, 4000);
    }
  };

  return (
    <>
      <ApiLoader status={loading.status} data={loading.data} />
      <AvatarEditor
        ref={canvasRef}
        image={image}
        borderRadius="100"
        width={400}
        height={400}
        scale={scale}
      />
      <FileBase64 multiple={false} onDone={(e) => handleImageUpload(e)} />
      <Button
        onClick={() => onClickSave()}
        variant="outlined"
        disabled={!image}
      >
        Save
      </Button>
      <Slider
        value={scale}
        onChange={handleSliderChange}
        step={0.01}
        min={1}
        max={2}
      />
    </>
  );
}
